.business {
  overflow-x: hidden;
  & .section-two {
    //   border: 2px solid red;
    min-height: 400px;
    padding: 80px 40px;
    z-index: 3;
    background: white;

    & .content-center {
      max-width: var(--width-standard);
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

    & p {
      width: 50%;
      font-size: 1.1rem;
      font-weight: 400;
      opacity: 0.75;
      text-transform: capitalize;
      line-height: 1.2;
      color: black;

      @media (max-width: $breakpoint-large) {
        width: 70%;
        font-size: 0.9rem;
      }
      @media (max-width: $breakpoint-tablet) {
        width: 80%;
        font-size: 0.7rem;
      }
    }

    & .content {
      // border: 2px solid blue;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;

      @media (max-width: $breakpoint-large) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      @media (max-width: $breakpoint-tablet) {
      }

      & .business-item {
        border: 1px solid var(--primary-blue);
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: black;
        border-radius: 10px;
        padding: 20px 15px;
        position: relative;
        z-index: 3;

        @media (max-width: $breakpoint-large) {
          padding: 18px 13px;
          width: 200px;
        }
        @media (max-width: $breakpoint-tablet) {
          padding: 16px 11px;
          width: 150px;
        }
        @media (max-width: $breakpoint-mobile) {
          padding: 16px 11px;
          width: 280px;
        }

        & span {
          font-size: 40px;
          color: var(--primary-blue);
          z-index: 3;

          @media (max-width: $breakpoint-large) {
            font-size: 35px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 30px;
          }
        }

        & p {
          z-index: 3;
          width: 100%;
          color: black;

          @media (max-width: $breakpoint-large) {
            font-size: 0.9rem;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 0.7rem;
          }
        }

        &:before {
          content: "";
          border-radius: inherit;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          right: 0;
          transform: scaleX(0);
          transform-origin: right;
          background-color: var(--primary-blue);
          transition: transform 0.5s ease 0s;
          // z-index: 2;
        }

        &:hover {
          & p,
          span {
            color: white;
          }
          &:before {
            top: 0;
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }
  }

  & .section-three {
    // max-width: var(--width-standard);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 60px;
    // justify-content: center;
    align-items: center;
    padding: 100px 40px;
    background-size: cover;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      background-color: rgba(21, 40, 74, 0.7);
    }

    & .heading {
      // width: var(--width-standard);
      width: 100%;
      z-index: 3;
    }

    & .image {
      z-index: 3;
      & img {
        height: 600px;
        width: 900px;

        @media (max-width: $breakpoint-large) {
          height: 400px;
          width: 700px;
        }
        @media (max-width: $breakpoint-tablet) {
          height: 350px;
          width: 500px;
        }
        @media (max-width: $breakpoint-mobile) {
          height: 300px;
          width: 400px;
        }
      }
    }
  }

  & .section-four {
    background: white;
    color: black;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    gap: 90px;

    & .heading {
      width: var(--width-standard);
      margin: 0 auto;
    }

    & .item-left,
    .item-right {
      display: flex;
      align-items: center;
      gap: 75px;
      max-width: var(--width-standard);
      margin: 0 auto;

      & .left {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & h2 {
          font-size: 30px;
          color: var(--primary-blue-200);

          @media (max-width: $breakpoint-large) {
            font-size: 25px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 20px;
          }
        }

        & ul {
          list-style-type: none;
          display: flex;
          flex-direction: column;
          gap: 15px;

          & li {
            font-size: 20px;
            text-align: justify;

            @media (max-width: $breakpoint-large) {
              font-size: 18px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 14px;
            }
            @media (max-width: $breakpoint-mobile) {
              font-size: 12px;
            }
          }
        }
      }

      & .right {
        @media (max-width: $breakpoint-large) {
          display: none;
        }
        & img {
          height: 390px;
          width: 540px;
          // border-radius: 50%;
          border-radius: 0.25rem;
          border-bottom-left-radius: 1rem;
          border-top-right-radius: 1rem;
          object-fit: cover;
        }
      }
    }
    // grid-template-columns: 1fr 1fr;
  }
}
