.landing {
  & .section-two {
    min-height: 500px;
    background: white;
    z-index: 4;
    position: relative;
    padding: 0 40px;

    & .content {
      max-width: var(--width-standard);
      margin: 0 auto;
      // border: 2px solid red;
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      gap: 40px;

      & .top {
        // border: 2px solid blue;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $breakpoint-tablet) {
          display: grid;
          grid-template-columns: 1fr;
          gap: 30px;
        }

        & .left {
          display: flex;
          gap: 1.5rem;
          flex-direction: column;
          // border: 2px solid red;

          & h1 {
            font-size: 3rem;
            letter-spacing: 1.5px;
            line-height: 1.3;
            font-weight: 500;
            text-transform: capitalize;
            color: var(--primary-blue);
            // color: black;

            @media (max-width: $breakpoint-large) {
              font-size: 2.5rem;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 2rem;
            }
          }

          & p {
            width: 55%;
            font-size: 1.1rem;
            font-weight: 400;
            opacity: 0.75;
            text-transform: capitalize;
            // color: var(--primary-blue);
            line-height: 1.2;
            color: black;
            // text-align: justify;

            @media (max-width: $breakpoint-large) {
              font-size: 0.9rem;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 0.7rem;
            }
          }
        }
        .right {
          & a {
            padding: 12px 36px;
            font-size: 1.15rem;
            border: 2px solid var(--primary-blue);
            border-top-right-radius: 10px;
            border-bottom-left-radius: 10px;
            text-transform: capitalize;
            transition: var(--transition);
            width: max-content;
            background: white;
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--primary-blue);
            text-decoration: none;

            @media (max-width: $breakpoint-large) {
              font-size: 0.9rem;
              padding: 10px 30px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 0.7rem;
              padding: 8px 25px;
            }

            &:hover {
              cursor: pointer;
              background: var(--primary-blue);
              transition: var(--transition);
              color: white;
              border-radius: 10px;
            }
          }
        }
      }

      .bottom {
        // border: 2px solid blue;
        min-height: 400px;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: center;
        justify-content: center;

        .service-item {
          width: 350px;
          border: 1px solid var(--primary-blue);
          color: black;
          display: flex;
          gap: 1.5rem;
          flex-direction: column;
          padding: 25px 20px;
          height: 330px;
          position: relative;
          transition: var(--transition-ease);

          @media (max-width: $breakpoint-tablet) {
            height: auto;
          }

          .icon {
            font-size: 48px;
            color: var(--primary-blue);
            z-index: 3;
            transition: var(--transition-ease);

            @media (max-width: $breakpoint-large) {
              font-size: 42px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 36px;
            }
          }

          h2 {
            font-size: 1.5rem;
            font-weight: 600;
            text-transform: capitalize;
            color: var(--primary-blue);
            z-index: 3;
            transition: var(--transition-ease);

            @media (max-width: $breakpoint-large) {
              font-size: 1.3rem;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 1rem;
            }
          }

          .service-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            z-index: 3;

            @media (max-width: $breakpoint-tablet) {
              gap: 1rem;
            }
          }

          ul {
            list-style-type: none;
            font-size: 1.1rem;
            line-height: 1.5;
            // opacity: 0.85;
            z-index: 3;

            @media (max-width: $breakpoint-large) {
              font-size: 0.9rem;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 0.7rem;
            }

            li {
              text-transform: capitalize;
              z-index: 3;
              color: black;
              transition: var(--transition-ease);
            }
          }

          & a {
            font-size: 16px;
            text-decoration: none;
            color: var(--primary-blue);
            // border: 2px solid var(--primary-blue-200);
            border: none;
            width: max-content;
            background: transparent;
            display: flex;
            align-items: center;
            gap: 10px;
            transition: var(--transition-ease);

            &:hover {
              opacity: 0.8;
              cursor: pointer;
              color: white;
            }
          }

          &:before {
            content: "";
            border-radius: inherit;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            // left: 0;
            right: 0;
            transform: scaleX(0);
            transform-origin: right;
            background-color: var(--primary-blue);
            // z-index: -3;
            transition: transform 0.5s ease 0s;
          }

          &:hover {
            & .icon {
              color: white;
              // color: var(--primary-blue-200);
            }

            & h2 {
              color: white;
              // color: var(--primary-blue-200);
            }

            & ul {
              & li {
                color: white;
              }
            }
            button {
              color: white;

              &:hover {
                color: var(--primary-blue-200);
              }
            }

            &:before {
              top: 0;
              // left: 0;
              // right: auto;
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }
      }
    }
  }
}
