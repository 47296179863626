.landing {
  & .section-three {
    min-height: 400px;
    padding: 80px 40px;
    background: var(--primary-blue);
    z-index: 4;
    position: relative;

    & .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: var(--width-standard);
      margin: 0 auto;
      // border: 2px solid red;

      @media (max-width: $breakpoint-large) {
        // display: none;
        grid-template-columns: 1fr;
      }

      & .left {
        display: flex;
        gap: 2.5rem;
        flex-direction: column;
        // border: 2px solid blue;

        & h1 {
          font-size: 3rem;
          letter-spacing: 1.5px;
          line-height: 1.3;
          font-weight: 600;
          text-transform: capitalize;
          color: white;
          // border: 2px solid red;

          @media (max-width: $breakpoint-large) {
            font-size: 2.7rem;
          }
          @media (max-width: $breakpoint-tablet) {
            width: 80%;
            font-size: 2.4rem;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 2rem;
          }
        }

        & p {
          // width: 65%;
          font-size: 1.1rem;
          font-weight: 400;
          opacity: 0.75;
          text-transform: capitalize;
          line-height: 1.2;
          color: white;

          @media (max-width: $breakpoint-large) {
            font-size: 0.9rem;
          }
          @media (max-width: $breakpoint-tablet) {
            width: 80%;
            font-size: 0.7rem;
          }
        }

        & .items {
          // border: 2px solid green;
          display: grid;
          // justify-content: space-between;
          grid-template-columns: 1fr 1fr;
          // flex-wrap: wrap;
          gap: 25px;

          & .about-item {
            & p {
              display: flex;
              gap: 20px;
              font-size: 24px;
              font-weight: 500;
              color: white;
              text-transform: capitalize;
              width: 200px;

              @media (max-width: $breakpoint-large) {
                width: 180px;
                font-size: 20px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 18px;
                width: 160px;
              }
              @media (max-width: $breakpoint-mobile) {
                font-size: 17px;
                width: 140px;
              }

              & span {
                font-size: 30px;
                padding: 0.75rem 1rem;
                color: var(--primary-blue);
                background: var(--primary-blue-200);
                border-radius: 0.25rem;

                @media (max-width: $breakpoint-large) {
                  font-size: 25px;
                }
                @media (max-width: $breakpoint-tablet) {
                  font-size: 20px;
                }
                @media (max-width: $breakpoint-mobile) {
                  font-size: 18px;
                }
              }
            }
          }
        }

        & a {
          padding: 12px 36px;
          font-size: 1.15rem;
          border: 2px solid var(--primary-blue-200);
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
          text-transform: capitalize;
          transition: var(--transition);
          width: max-content;
          background: var(--primary-blue-200);
          color: white;
          text-decoration: none;

          @media (max-width: $breakpoint-large) {
            font-size: 0.9rem;
            padding: 10px 30px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 0.7rem;
            padding: 8px 24px;
          }

          &:hover {
            cursor: pointer;
            color: var(--primary-blue-200);
            background: white;
            transition: var(--transition);
            border-radius: 10px;
          }
        }
      }

      & .right {
        display: flex;
        align-items: center;

        @media (max-width: $breakpoint-large) {
          display: none;
        }
        & .image {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            height: 419px;
            width: 456px;
            // background: rgba(21, 40, 74, 0.55);
            top: 0;
            right: 0;
            border: 5px solid var(--primary-blue-200);
            border-radius: 0.25rem;
            border-bottom-left-radius: 1rem;
            border-top-right-radius: 1rem;
          }
          & img {
            height: 419px;
            width: 456px;
            object-fit: fill;
            border-radius: 0.25rem;
            border-bottom-left-radius: 1rem;
            border-top-right-radius: 1rem;
          }
        }
      }
    }
  }
}
