.landing {
  & .section-four {
    // border: 2px solid red;
    min-height: 400px;
    padding: 80px 40px;
    z-index: 3;
    background: white;
    z-index: 4;
    position: relative;

    & .content-center {
      // border: 2px solid blue;
      gap: 50px;
      max-width: var(--width-standard);
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }

    & .top {
      display: flex;
      justify-content: space-between;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        gap: 30px;
      }

      & a {
        padding: 12px 36px;
        font-size: 1.15rem;
        border: 2px solid var(--primary-blue);
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        text-transform: capitalize;
        transition: var(--transition);
        width: max-content;
        background: white;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--primary-blue);
        text-decoration: none;

        @media (max-width: $breakpoint-large) {
          font-size: 0.9rem;
          padding: 10px 30px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 0.7rem;
          padding: 8px 24px;
        }

        &:hover {
          cursor: pointer;
          background: var(--primary-blue);
          transition: var(--transition);
          color: white;
          border-radius: 10px;
        }
      }
    }

    & p {
      width: 50%;
      font-size: 1.1rem;
      font-weight: 400;
      opacity: 0.75;
      text-transform: capitalize;
      line-height: 1.2;
      color: black;

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }
    }

    & .content {
      // border: 2px solid blue;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;

      @media (max-width: $breakpoint-large) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      & .business-item {
        border: 1px solid var(--primary-blue);
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: black;
        border-radius: 10px;
        padding: 20px 15px;
        position: relative;
        z-index: 3;

        @media (max-width: $breakpoint-large) {
          width: 300px;
        }
        @media (max-width: $breakpoint-tablet) {
          width: 250px;
        }
        @media (max-width: $breakpoint-mobile) {
          width: 300px;
        }

        & span {
          font-size: 40px;
          color: var(--primary-blue);
          z-index: 3;
        }

        & p {
          z-index: 3;
          width: 100%;
          color: black;
        }

        &:before {
          content: "";
          border-radius: inherit;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          right: 0;
          transform: scaleX(0);
          transform-origin: right;
          background-color: var(--primary-blue);
          transition: transform 0.5s ease 0s;
          // z-index: 2;
        }

        &:hover {
          & p,
          span {
            color: white;
          }
          &:before {
            top: 0;
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }
  }
}
