.about {
  .section-two {
    min-height: 400px;
    padding: 80px 40px;
    background: white;

    & .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: var(--width-standard);
      margin: 0 auto;
      // border: 2px solid red;

      @media (max-width: $breakpoint-large) {
        grid-template-columns: 1fr;
      }

      & .left {
        display: flex;
        gap: 2.5rem;
        flex-direction: column;
        // border: 2px solid blue;

        & h1 {
          font-size: 3rem;
          letter-spacing: 1.5px;
          line-height: 1.3;
          font-weight: 600;
          text-transform: capitalize;
          color: black;

          @media (max-width: $breakpoint-large) {
            width: 70%;
            font-size: 2.5rem;
          }
          @media (max-width: $breakpoint-tablet) {
            width: 80%;
            font-size: 2rem;
          }
        }

        & p {
          // width: 65%;
          font-size: 1.1rem;
          font-weight: 400;
          opacity: 0.75;
          text-transform: capitalize;
          line-height: 1.2;
          color: black;

          @media (max-width: $breakpoint-large) {
            font-size: 0.9rem;
            width: 70%;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 0.7rem;
            width: 80%;
          }
        }

        & .items {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 25px;

          @media (max-width: $breakpoint-mobile) {
            grid-template-columns: 1fr;
          }

          & .about-item {
            display: flex;
            gap: 20px;
            font-size: 24px;
            font-weight: 500;
            color: black;
            text-transform: capitalize;
            width: 200px;

            @media (max-width: $breakpoint-large) {
              font-size: 20px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 16px;
            }

            & span {
              font-size: 30px;
              padding: 0.75rem 1rem;
              color: var(--primary-blue);
              background: var(--primary-blue-200);
              border-radius: 0.25rem;

              @media (max-width: $breakpoint-large) {
                font-size: 25px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 20px;
              }
            }
          }
        }

        & button {
          padding: 12px 36px;
          font-size: 1.15rem;
          border: 2px solid var(--primary-blue-200);
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
          text-transform: capitalize;
          transition: var(--transition);
          width: max-content;
          background: var(--primary-blue-200);
          color: white;

          @media (max-width: $breakpoint-large) {
            padding: 10px 32px;
            font-size: 0.9rem;
          }
          @media (max-width: $breakpoint-tablet) {
            padding: 8px 28px;
            font-size: 0.7rem;
          }

          &:hover {
            cursor: pointer;
            color: var(--primary-blue-200);
            background: white;
            transition: var(--transition);
            border-radius: 10px;
          }
        }
      }

      & .right {
        display: flex;
        align-items: center;

        @media (max-width: $breakpoint-large) {
          display: none;
        }
        & .image {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            height: 419px;
            width: 456px;
            // background: rgba(21, 40, 74, 0.55);
            top: 0;
            right: 0;
            border: 5px solid var(--primary-blue-200);
            border-radius: 0.25rem;
            border-bottom-left-radius: 1rem;
            border-top-right-radius: 1rem;
          }
          & img {
            height: 419px;
            width: 456px;
            border-radius: 0.25rem;
            border-bottom-left-radius: 1rem;
            border-top-right-radius: 1rem;
            object-fit: fill;
          }
        }
      }
    }
  }

  & .section-three {
    min-height: 400px;
    background: white;
    padding: 100px 40px;
    background: var(--primary-blue);
    color: white;

    & .content {
      // border: 2px solid red;
      display: flex;
      max-width: var(--width-standard);
      margin: 0 auto;
      gap: 60px;

      & .left {
        position: relative;
        margin-left: 100px;

        @media (max-width: $breakpoint-large) {
          display: none;
        }

        & .people {
          height: 450px;
          width: 392px;
          object-fit: cover;
          border-radius: 3px;
        }

        & .research {
          position: absolute;
          bottom: -40px;
          left: -100px;
          height: 200px;
          width: 300px;
          object-fit: cover;
          border: 10px solid white;
          box-sizing: content-box;
        }
      }

      & .right {
        // border: 2px solid blue;
        display: flex;
        flex-direction: column;
        gap: 20px;

        & h3 {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 5px;
          color: var(--primary-blue);

          @media (max-width: $breakpoint-large) {
            font-size: 14px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 12px;
          }
        }

        & h1 {
          font-size: 35px;
          font-weight: 700;
          letter-spacing: -1px;

          @media (max-width: $breakpoint-large) {
            font-size: 30px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 25px;
          }
        }

        & .lines {
          display: flex;
          gap: 10px;

          & .dot-line {
            width: 104px;
            height: 5px;
            border-radius: 6px;
            background: var(--primary-blue);
          }

          & .dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: var(--primary-blue);
          }
        }

        & p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          opacity: 0.85;

          @media (max-width: $breakpoint-large) {
            font-size: 14px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 11px;
          }
        }

        & .bottom {
          display: flex;
          flex-direction: column;
          gap: 20px;

          & .item {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 30px;
            align-items: center;
            justify-content: center;

            & .icon {
              font-size: 40px;
              color: var(--primary-blue);
              width: 80px;
              height: 80px;
              border-radius: 100%;
              box-shadow: 0 10px 10px 0px rgba(37, 37, 37, 0.4);
              background: white;
              display: flex;
              align-items: center;
              justify-content: center;

              @media (max-width: $breakpoint-large) {
                font-size: 35px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 30px;
              }
            }

            & .text {
              display: flex;
              flex-direction: column;
              //   width: 50%;
              gap: 15px;
              & h2 {
                font-size: 20px;
                // font-weight: 500;

                @media (max-width: $breakpoint-large) {
                  font-size: 18px;
                }
                @media (max-width: $breakpoint-tablet) {
                  font-size: 16px;
                }
              }
              & p {
                font-size: 15px;
                font-weight: 400;

                @media (max-width: $breakpoint-large) {
                  font-size: 13px;
                }
                @media (max-width: $breakpoint-tablet) {
                  font-size: 11px;
                }
              }
            }
          }

          & .objectives {
            & .text {
              width: 100%;
              & p {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;

                @media (max-width: $breakpoint-mobile) {
                  grid-template-columns: 1fr;
                }
              }
            }
          }
        }
      }
    }
  }

  & .section-four {
    min-height: 400px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 40px 40px 80px;
    background: var(--padding-blue);

    & .heading {
      max-width: var(--width-standard);
      margin: 0 auto;
      width: 100%;
    }

    & .content {
      //   border: 2px solid red;
      margin: 0 auto;
      max-width: var(--width-standard);
      display: flex;
      gap: 60px;

      & .left {
        display: flex;
        flex-direction: column;
        gap: 50px;
        // justify-content: space-between;

        & p {
          font-size: 18px;
          text-align: justify;
          line-height: 24px;
          color: white;
          opacity: 0.85;
          letter-spacing: 1px;

          @media (max-width: $breakpoint-large) {
            font-size: 15px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 11px;
          }
        }
      }

      & .right {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media (max-width: $breakpoint-large) {
          // font-size: 25px;
          display: none;
        }

        & .image {
          position: relative;
          height: 370px;
          width: 552px;

          & img {
            height: 370px;
            width: 552px;
            border-radius: 0.25rem;
            border-bottom-left-radius: 1rem;
            border-top-right-radius: 1rem;
            object-fit: cover;
          }

          &:after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(21, 40, 74, 0.5);
            top: 0;
            left: 0;
            z-index: 3;
            border-radius: 0.25rem;
            border-bottom-left-radius: 1rem;
            border-top-right-radius: 1rem;
          }
        }

        & p {
          font-size: 18px;
          line-height: 24px;
          color: black;
          text-align: justify;
          opacity: 0.85;
          letter-spacing: 1px;
        }
      }
    }
  }

  & .section-five {
    min-height: 400px;
    color: black;

    background: white;

    & .content-center {
      max-width: var(--width-standard);
      padding: 100px 40px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin: 0 auto;
    }

    & p {
      font-size: 20px;
      line-height: 24px;
      width: 55%;

      @media (max-width: $breakpoint-large) {
        font-size: 18px;
        width: 70%;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 16px;
        width: 85%;
      }
    }

    & .content {
      // border: 2px solid red;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
      grid-auto-rows: auto;

      @media (max-width: $breakpoint-large) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: repeat(1, 1fr);
      }

      & .item {
        // height: 200px;
        padding: 20px;
        border: 1px solid var(--primary-blue);
        border-radius: 3px;
        display: flex;
        // align-items: center;
        // justify-content: center;
        position: relative;

        @media (max-width: $breakpoint-tablet) {
          min-height: 170px;
        }

        &:before {
          content: "";
          border-radius: inherit;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          // left: 0;
          right: 0;
          transform: scaleX(0);
          transform-origin: left;
          background-color: var(--primary-blue);
          // z-index: -3;
          transition: transform 0.5s ease 0s;
        }

        &:hover {
          & h2 {
            color: var(--primary-blue);
          }
          & span {
            // transform: translateZ(10px);
            background: var(--primary-blue);
            transform: rotateY(180deg);
          }

          & hr {
            background: var(--primary-blue);
          }

          & p {
            color: white;
          }
          &:before {
            top: 0;
            transform: scaleX(1);
            transform-origin: left;
          }
        }

        & p {
          width: 100%;
          font-size: 17px;
          letter-spacing: 1px;
          line-height: 24px;
          text-align: center;
          z-index: 4;
        }
      }
    }
  }

  & .section-six {
    min-height: 400px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin: 0 auto;
    justify-content: center;
    color: white;
    padding: 70px 40px 70px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    flex-wrap: wrap;
    // border: 2px solid red;
    // align-items: center;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(21, 40, 74, 0.65);
      top: 0;
      left: 0;
    }

    & .main {
      display: grid;
      max-width: var(--width-standard);
      //   flex-direction: column;
      grid-template-rows: auto 1fr auto auto;
      grid-auto-rows: auto;
      border: 1px solid var(--primary-blue-200);
      gap: 20px;
      padding: 20px;
      width: 300px;
      border-radius: 4px;
      position: relative;
      z-index: 3;

      @media (max-width: $breakpoint-large) {
        padding: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        padding: 16px;
      }

      & span {
        padding: 15px;
        width: fit-content;
        font-size: 50px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--primary-blue-200);
        z-index: 4;
        transform: rotateY(0);

        transition: var(--transition);

        @media (max-width: $breakpoint-large) {
          padding: 13px;
        }
        @media (max-width: $breakpoint-tablet) {
          padding: 11px;
        }
      }

      & h2 {
        font-size: 22px;
        font-weight: 700;
        z-index: 4;

        @media (max-width: $breakpoint-large) {
          font-size: 20px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 18px;
        }
      }

      & hr {
        width: 43px;
        height: 2px;
        background: var(--primary-blue-200);
        border: none;
        z-index: 4;
      }

      & .items {
        height: 220px;
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        gap: 15px;
        z-index: 4;

        & p {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 1px;
          //   line-height: 15px;

          @media (max-width: $breakpoint-large) {
            font-size: 14px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 12px;
          }
        }
      }

      &:before {
        content: "";
        border-radius: inherit;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        // left: 0;
        right: 0;
        transform: scaleX(0);
        transform-origin: left;
        background-color: rgba(255, 255, 255, 0.95);
        // z-index: -3;
        transition: transform 0.5s ease 0s;
      }

      &:hover {
        & h2 {
          color: var(--primary-blue);
        }
        & span {
          // transform: translateZ(10px);
          background: var(--primary-blue);
          transform: rotateY(180deg);
        }

        & hr {
          background: var(--primary-blue);
        }

        & .items {
          & p {
            color: var(--primary-blue);
          }
        }
        &:before {
          top: 0;
          transform: scaleX(1);
          transform-origin: left;
        }
      }
    }
  }
}
