.navbar {
  // border: 2px solid red;
  min-height: 90px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  @media (max-width: $breakpoint-tablet) {
  }
  @media (max-width: $breakpoint-large) {
  }

  & .center {
    width: var(--width-standard);
    height: 100%;
    // border: 2px solid blue;
    display: flex;
    align-items: center;
    padding: 0px 40px;
    position: relative;

    @media (max-width: $breakpoint-tablet) {
      justify-content: space-between;
    }

    & img {
      height: 60px;
      width: 200px;
      padding: 5px 10px;
      box-sizing: content-box;
      cursor: pointer;

      @media (max-width: $breakpoint-large) {
        height: 50px;
        width: 180px;
      }
      @media (max-width: $breakpoint-tablet) {
        height: 40px;
        width: 160px;
      }
    }

    & .links {
      display: flex;
      gap: 30px;
      // border: 2px solid red;
      width: 100%;
      justify-content: center;

      @media (max-width: $breakpoint-tablet) {
        // display: none;
        position: absolute;
        top: 72px;
        left: 0;
        width: 100%;
        height: 0px;
        background: var(--primary-blue);
        flex-direction: column;
        padding: 0 40px;
        transition: var(--transition);
      }

      & .item {
        cursor: pointer;
        text-decoration: none;
        text-transform: capitalize;
        transition: var(--transition);
        font-size: 1rem;
        font-weight: 600;
        // line-height: 90px;
        color: white;
        // border: 2px solid blue;
        width: fit-content;

        @media (max-width: $breakpoint-large) {
          font-size: 0.8rem;
        }
        @media (max-width: $breakpoint-tablet) {
          transform: translateX(-100px);
          opacity: 0;
          transition: var(--transition);
        }

        &:hover {
          color: var(--primary-blue-200);
          transition: var(--transition);
        }
      }

      & .active {
        color: var(--primary-blue-200);
      }
    }
    @media (max-width: $breakpoint-tablet) {
      & .nav-active {
        height: 400px;
        transition: var(--transition);

        & .item {
          transform: translateX(0);
          opacity: 1;
          transition: var(--transition);
        }
      }
    }

    & span {
      display: none;

      @media (max-width: $breakpoint-tablet) {
        display: flex;
      }

      & svg {
        height: 2rem;
        width: 2rem;
        color: white;
        cursor: pointer;

        @media (max-width: $breakpoint-large) {
          height: 1.7rem;
          width: 1.7rem;
        }
        @media (max-width: $breakpoint-tablet) {
          height: 1.3rem;
          width: 1.3rem;
        }
      }
    }
  }
}

.navbar-scrolled {
  // background: var(--primary-blue-200);
  // background: rgb(42, 111, 201);
  background: white;
  box-shadow: 1px 1px 14px 1px rgb(228, 224, 224);

  & .center {
    & .links {
      & .item {
        color: var(--primary-blue);

        @media (max-width: $breakpoint-tablet) {
          color: white;
        }
      }

      & .active {
        color: var(--primary-blue-200);
      }
    }

    & span {
      & svg {
        color: var(--primary-blue);
      }
    }
  }
}
