@import "./modules/variables";
@import "./modules/navbar";
@import "./modules/landing";
@import "./modules/section-one";
@import "./modules/section-two";
@import "./modules/section-three";
@import "./modules/section-four";
@import "./modules/footer";
@import "./modules/about";
@import "./modules/services";
@import "./modules/business";
@import "./modules/resources";
@import "./modules/contact";

.page-header {
  min-height: 430px;
  // margin-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: white;
  background-size: cover;
  position: relative;
  // border: 2px solid red;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(21, 40, 74, 0.7);
  }

  & h1 {
    font-weight: 700;
    font-size: 48px;
    z-index: 3;
    text-transform: capitalize;

    @media (max-width: $breakpoint-large) {
      font-size: 45px;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 40px;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 35px;
    }
  }

  & p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
    z-index: 3;

    @media (max-width: $breakpoint-large) {
      font-size: 14px;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 12px;
    }
  }

  & a {
    cursor: pointer;
    color: var(--primary-blue-200);
    transition: var(--transition);
    text-decoration: none;

    &:hover {
      color: white;
      transition: var(--transition);
    }
  }
}
