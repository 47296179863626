.services {
  //   border: 2px solid red;
  min-height: 400px;
  & .section-two {
    min-height: 500px;
    background: white;

    & .content {
      max-width: var(--width-standard);
      margin: 0 auto;
      // border: 2px solid red;
      padding: 60px 40px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      & .top {
        // border: 2px solid blue;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .left {
          display: flex;
          gap: 1.5rem;
          flex-direction: column;

          & h1 {
            font-size: 3rem;
            letter-spacing: 1.5px;
            line-height: 1.3;
            font-weight: 500;
            text-transform: capitalize;
            color: var(--primary-blue);
            // color: black;

            @media (max-width: $breakpoint-large) {
              font-size: 2.5rem;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 2rem;
            }
          }

          & p {
            width: 55%;
            font-size: 1.1rem;
            font-weight: 400;
            opacity: 0.75;
            text-transform: capitalize;
            // color: var(--primary-blue);
            line-height: 1.2;
            color: black;
            // text-align: justify;

            @media (max-width: $breakpoint-large) {
              font-size: 0.9rem;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 0.7rem;
            }
          }
        }
        .right {
          & a {
            padding: 12px 36px;
            font-size: 1.15rem;
            border: 2px solid var(--primary-blue);
            border-top-right-radius: 10px;
            border-bottom-left-radius: 10px;
            text-transform: capitalize;
            transition: var(--transition);
            width: max-content;
            background: white;
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--primary-blue);
            text-decoration: none;

            &:hover {
              cursor: pointer;
              background: var(--primary-blue);
              transition: var(--transition);
              color: white;
              border-radius: 10px;
            }
          }
        }
      }

      & .bottom {
        // border: 2px solid blue;
        min-height: 400px;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: center;
        justify-content: space-between;

        .service-item {
          width: 100%;
          color: black;
          display: flex;
          gap: 1.5rem;
          flex-direction: row;
          height: fit-content;
          justify-content: space-between;
          align-items: center;
          padding: 25px 0px;
          min-height: 330px;
          position: relative;
          transition: var(--transition-ease);

          .icon {
            padding: 0;
            font-size: 408px;
            color: white;
            background: var(--primary-blue);
            z-index: 3;
            transition: var(--transition-ease);
            width: 408px;
            height: 408px;
            border-radius: 10px;

            @media (max-width: $breakpoint-large) {
              display: none;
            }
          }

          h2 {
            // display: inline-block;
            font-size: 3.5rem;
            font-weight: 600;
            text-transform: capitalize;
            color: var(--primary-blue);
            z-index: 3;
            transition: var(--transition-ease);

            @media (max-width: $breakpoint-large) {
              font-size: 3rem;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 2.5rem;
            }
          }

          & .service-content {
            // border: 2px solid green;
            display: flex;
            flex-direction: column;
            height: 100%;
            // justify-content: space-between;
            gap: 30px;
            z-index: 3;
            width: 55%;

            @media (max-width: $breakpoint-large) {
              width: 70%;
            }
            @media (max-width: $breakpoint-tablet) {
              width: 80%;
            }
          }

          ul {
            list-style-type: none;
            font-size: 1.5rem;
            line-height: 1.5;
            // opacity: 0.85;
            z-index: 3;

            li {
              text-transform: capitalize;
              z-index: 3;
              color: black;
              transition: var(--transition-ease);
            }
          }

          & p {
            color: black;
            font-size: 1.1rem;
            line-height: 1.5;
            z-index: 3;
            text-align: justify;

            @media (max-width: $breakpoint-large) {
              font-size: 0.9rem;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 0.7rem;
            }
          }

          button {
            font-size: 16px;
            color: var(--primary-blue);
            border: none;
            width: max-content;
            background: transparent;
            display: flex;
            align-items: center;
            gap: 10px;
            transition: var(--transition-ease);

            &:hover {
              opacity: 0.8;
              cursor: pointer;
              color: white;
            }
          }
        }
        & .reversed {
          flex-direction: row-reverse;

          @media (max-width: $breakpoint-large) {
            // font-size: 0.9rem;
            flex-direction: row;
          }
        }
      }
    }
  }

  & .section-three {
    // border: 2px solid red;

    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 100px 0;

    & .heading {
      max-width: var(--width-standard);
      margin: 0 auto;
    }

    & .items {
      // border: 2px solid blue;
      max-width: var(--width-standard);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;
      justify-content: center;
      align-items: center;

      & .item {
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: white;
        border: 1px solid var(--primary-blue-200);
        border-radius: 10px;
        padding: 20px;
        width: 300px;
        height: 200px;
        position: relative;

        @media (max-width: $breakpoint-large) {
          padding: 18px;
          width: 280px;
          height: 180px;
        }
        @media (max-width: $breakpoint-tablet) {
          padding: 16px;
          width: 260px;
          height: 160px;
        }

        &:before {
          content: "";
          border-radius: inherit;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          // left: 0;
          right: 0;
          transform: scaleX(0);
          transform-origin: right;
          background-color: white;
          // z-index: -3;
          transition: transform 0.5s ease 0s;
        }

        &:hover {
          & span {
            color: var(--primary-blue);
          }
          & p {
            color: var(--primary-blue);
          }

          &:before {
            top: 0;
            // left: 0;
            // right: auto;
            transform: scaleX(1);
            transform-origin: left;
          }
        }

        & span {
          font-size: 40px;
          z-index: 4;

          @media (max-width: $breakpoint-large) {
            font-size: 35px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 30px;
          }
        }
        & p {
          font-size: 30px;
          text-transform: capitalize;
          z-index: 4;

          @media (max-width: $breakpoint-large) {
            font-size: 25px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
