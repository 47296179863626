.contact {
  position: relative;
  & .section-two {
    background: var(--secondary-grey);
    color: white;
    // padding: 40px;
    min-height: 470px;
    color: white;

    position: relative;
    display: flex;
    align-items: center;

    & .content {
      width: var(--width-standard);
      // border: 2px solid red;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0 40px;

      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: 1fr;
      }

      & .left {
        // width: 50%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: var(--primary-blue);
        display: flex;
        flex-direction: column;
        gap: 40px;

        & h2 {
          font-size: 43px;
          // text-transform: capitalize;
          @media (max-width: $breakpoint-large) {
            font-size: 35px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 30px;
          }
        }

        & p {
          text-transform: capitalize;
          font-size: 18px;
          width: 80%;
          line-height: 30px;

          @media (max-width: $breakpoint-large) {
            font-size: 16px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 13px;
          }
        }
      }

      & .right {
        // background: white;
        // width: 700px;
        width: 50%;
        position: absolute;
        right: 0;
        top: 0;

        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
        & img {
          height: 575px;
          width: 100%;
          // object-fit: cover;
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
        }
      }
    }
  }

  & .section-three {
    // border: 2px solid red;
    min-height: 450px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    padding: 40px;
    position: relative;
    z-index: 2;

    & .top {
      background: white;
      min-height: 450px;
      display: flex;
      gap: 40px;
      justify-content: center;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }

      & .item {
        border: 1px solid rgb(209, 211, 212);
        min-height: 250px;
        width: 40%;
        transform: translateY(-110px);
        background: white;
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        // justify-content: center;
        padding: 40px;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
        }

        & span {
          font-size: 45px;

          @media (max-width: $breakpoint-large) {
            font-size: 40px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 35px;
          }
        }

        & h3 {
          text-transform: capitalize;
          font-size: 20px;

          @media (max-width: $breakpoint-large) {
            font-size: 18px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
          }
        }

        & p {
          font-size: 15px;
          text-transform: capitalize;
          text-align: center;
          line-height: 30px;

          @media (max-width: $breakpoint-large) {
            font-size: 13px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 11px;
          }
        }

        & .phone {
          color: black;
        }

        & button {
          border: none;
          padding: 20px 25px;
          background: var(--primary-blue);
          border-radius: 0.25rem;
          border-bottom-left-radius: 1rem;
          border-top-right-radius: 1rem;
          color: white;
          text-transform: capitalize;
          font-size: 15px;
          cursor: pointer;
          transition: var(--transition);
          border: 1px solid var(--primary-blue);

          @media (max-width: $breakpoint-large) {
            // border-radius: 10px;
            padding: 15px 20px;
            font-size: 13px;
          }
          @media (max-width: $breakpoint-tablet) {
            // border-radius: 8px;
            padding: 13px 18px;
            font-size: 11px;
          }

          &:hover {
            background: white;
            border: 1px solid var(--primary-blue);
            color: var(--primary-blue);
            transition: var(--transition);
          }
        }
      }
    }

    & h2 {
      text-align: center;
      font-size: 35px;
      text-transform: capitalize;

      @media (max-width: $breakpoint-large) {
        font-size: 30px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 25px;
      }
    }

    & .map-center {
      // margin: 0 80px;
      display: grid;
      grid-template-columns: 65% 30%;
      gap: 40px;
      // width: var(--width-standard);
      width: 80%;
      margin: 35px auto;
      background-color: #f6f9fc;
      border-radius: 0.1875rem;
      box-shadow: 0 1px 5px 0 rgba(33, 51, 67, 0.12);

      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: 100%;
      }

      & .desc {
        display: flex;
        flex-direction: column;
        padding: 30px 0;
        gap: 30px;
        // border: 2px solid red;

        @media (max-width: $breakpoint-tablet) {
          padding: 30px;
        }

        & .desc-item {
          display: flex;
          flex-direction: column;
          gap: 30px;

          & h2 {
            font-size: 25px;

            @media (max-width: $breakpoint-large) {
              font-size: 20px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 18px;
            }
          }

          & .address {
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-size: 15px;

            @media (max-width: $breakpoint-large) {
              font-size: 13px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  & .form {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(128, 128, 128, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    form {
      background: white;
      padding: 50px 60px;
      display: flex;
      gap: 30px;
      flex-direction: column;
      width: 596px;
      position: relative;

      & h2 {
        text-align: center;
      }

      & p {
        text-align: center;
      }

      & .names {
        display: flex;
        gap: 20px;
        // display: grid;
        // grid-template-columns: auto auto;
        // width: 100%;

        & .name {
          display: flex;
          flex-direction: column;
          gap: 15px;
          // width: 50%;

          & input {
            padding: 15px 20px;
            font-size: 17px;
          }
        }
      }
      & .email,
      .message {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        & input {
          padding: 15px 20px;
          font-size: 17px;
        }

        & textarea {
          height: 180px;
          resize: none;
        }
      }

      & button {
        border: none;
        padding: 20px 25px;
        background: var(--primary-blue);
        border-radius: 15px;
        color: white;
        text-transform: capitalize;
        font-size: 15px;
        cursor: pointer;
        transition: var(--transition);
        border: 1px solid var(--primary-blue);

        &:hover {
          background: white;
          border: 1px solid var(--primary-blue);
          color: var(--primary-blue);
          transition: var(--transition);
        }
      }

      & .icon {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 25px;
        cursor: pointer;
      }
    }
  }
}
