footer {
  background: var(--primary-purple);
  color: white;

  display: flex;
  flex-direction: column;
  gap: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 40px;
  position: relative;
  overflow-x: hidden;
  z-index: 4;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(21, 40, 74, 0.65);
    top: 0;
    left: 0;
  }

  & .content {
    display: flex;
    width: var(--width-standard);
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: wrap;
    // gap: 50px;
    justify-content: space-between;
    z-index: 3;

    @media (max-width: $breakpoint-large) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      gap: 80px;
      row-gap: 40px;
    }
    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
    }
  }

  & .top {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;
    // border: 2px solid red;

    & img {
      width: 160px;
      height: 60px;
      // background: white;
      padding: 10px;

      @media (max-width: $breakpoint-large) {
        width: 150px;
        height: 55px;
      }
      @media (max-width: $breakpoint-tablet) {
        width: 130px;
        height: 50px;
      }
    }

    & .text {
      display: flex;
      gap: 10px;

      & span {
        // color: var(--primary-purple);
        color: white;
        font-size: 18px;

        @media (max-width: $breakpoint-large) {
          font-size: 16px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 14px;
        }
      }

      & p {
        font-size: 14px;

        @media (max-width: $breakpoint-large) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 11px;
        }
      }
    }
  }

  & hr {
    height: 1px;
    border: none;
    background: rgba(255, 255, 255, 0.4);
  }

  & .item {
    // border: 2px solid green;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: fit-content;

    & h1 {
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: rgba(255, 255, 255, 0.75);

      @media (max-width: $breakpoint-large) {
        font-size: 14px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 12px;
      }
    }

    & p {
      font-size: 14px;

      @media (max-width: $breakpoint-large) {
        font-size: 12px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 11px;
      }

      & .links {
        text-decoration: none;
        color: white;
      }
    }

    & .icons {
      display: flex;
      gap: 20px;
      font-size: 20px;

      @media (max-width: $breakpoint-large) {
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 16px;
      }
    }
  }
  // }

  & .copyright {
    text-transform: unset;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    letter-spacing: 1px;
    width: var(--width-standard);
    margin: 0 auto;
    z-index: 3;

    @media (max-width: $breakpoint-large) {
      font-size: 11px;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 10px;
    }
  }
}
