.landing {
  // background: var(--primary-blue);

  & .section-one {
    // border: 2px solid red;

    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    min-height: 100vh;
    padding: 0 40px;

    // backgroun

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(21, 40, 74, 0.7);
      top: 0;
      left: 0;
      z-index: 2;
    }

    & .particles {
      position: absolute;
      z-index: 3;
    }

    & .center {
      max-width: var(--width-standard);
      min-height: 521px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      color: white;
      align-items: center;
      z-index: 3;

      @media (max-width: $breakpoint-tablet) {
      }
      @media (max-width: $breakpoint-large) {
        grid-template-columns: 1fr;
      }
    }

    & .left {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 190px 0 100px;
      z-index: 3;
      // border: 2px solid red;

      @media (max-width: $breakpoint-large) {
        // width: 100%;
      }

      .icons {
        display: flex;
        gap: 30px;

        span {
          height: 17px;
          width: 17px;
          font-size: 20px;
          cursor: pointer;

          @media (max-width: $breakpoint-large) {
            height: 15px;
            width: 15px;
            font-size: 17px;
          }
          @media (max-width: $breakpoint-tablet) {
            height: 12px;
            width: 12px;
            font-size: 14px;
          }
        }
      }

      & h1 {
        font-size: 3.5rem;
        font-weight: 700;
        text-transform: capitalize;
        line-height: 1.3;
        position: relative;

        @media (max-width: $breakpoint-large) {
          font-size: 3rem;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 2.5rem;
        }

        & span {
          color: var(--primary-blue-200);
        }

        & .rounded-shape {
          background-image: repeating-linear-gradient(
            -55deg,
            transparent 0.9px,
            var(--primary-blue-200) 1.25px,
            var(--primary-blue-200) 3px,
            transparent 4px,
            transparent 8px
          );

          border-radius: 50%;

          position: absolute;
          width: 150px;
          height: 150px;
          opacity: 0.25;
          z-index: -1;
          left: 0;
          top: -30px;
          transform: translate(-25%, -25%);
        }
      }

      & p {
        font-size: 1.25rem;
        font-weight: 400;
        opacity: 0.75;

        @media (max-width: $breakpoint-large) {
          font-size: 1rem;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 0.8rem;
        }
      }

      & .button {
        a {
          font-size: 1.15rem;
          border: 2px solid var(--primary-blue-200);
          border-radius: 0.25rem;
          border-bottom-left-radius: 1rem;
          border-top-right-radius: 1rem;
          text-transform: capitalize;
          transition: var(--transition);
          padding: 12px 36px;
          color: var(--primary-blue-200);
          background: white;
          text-decoration: none;

          padding: 0.75rem 1.5rem;
          z-index: 3;
          position: relative;

          @media (max-width: $breakpoint-large) {
            font-size: 1rem;
            padding: 10px 30px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 0.8rem;
            padding: 8px 25px;
          }

          &:hover {
            cursor: pointer;
            background: var(--primary-blue-200);
            transition: var(--transition);
            color: white;
            border-radius: 10px;
          }
        }
      }
    }

    & .right {
      z-index: 3;
      @media (max-width: $breakpoint-large) {
        display: none;
      }
      & .image {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          height: 439px;
          width: 476px;
          // background: rgba(21, 40, 74, 0.55);
          top: 0;
          right: 0;
          border: 5px solid var(--primary-blue-200);
          border-radius: 0.25rem;
          border-bottom-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
        & img {
          height: 439px;
          width: 476px;
          object-fit: cover;
          border-radius: 0.25rem;
          border-bottom-left-radius: 1rem;
          border-top-right-radius: 1rem;
          // padding: 10px;
        }
      }
    }
  }
}
